
import { suggestResponsables } from '@/app/shared/global/services/admin-service';
import { suggestEtiquetas } from '@/app/shared/global/services/etiqueta-service';
import { suggestEmpresas } from '@/app/shared/global/services/empresa-service';

import { ref } from 'vue';
import { suggestProductos } from '@/app/shared/global/services/producto-service';
import { suggestContactos } from '@/app/shared/global/services/publico-service';
import { removeAccents } from '@/app/shared/utils/tools';

function isValidURL(url) {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
        'i',
    )

    return pattern.test(url)
}


const DEFAULT_DEBOUNCE_DURATION_IN_MILLIS = 1_000

function debounce(callback, wait) {
  let timeoutId = null

  return (...args) => {

    if (timeoutId) window.clearTimeout(timeoutId);

    timeoutId = window.setTimeout(() => {
      callback.apply(null, args)
    }, wait)
  }
}

export function useAutocomplete(
    // url,
    {
        // queryParam,
        transformData = (data) => data,
        debounceDuration = DEFAULT_DEBOUNCE_DURATION_IN_MILLIS,
    },
) {

    // if (!isValidURL(url)) {
    //     throw new Error(`${url} no es una válida URL!`)
    // }

    // if (!queryParam) {
    //     throw new Error(`'queryParam' option is required`)
    // }

    const isLoading = ref(false)
    const data = ref([])
    const hasFailed = ref(false)

    async function fetchSuggestions(tipo, modelo, query, suggestModel = '') {
      console.warn(suggestModel)

      try {
        isLoading.value = true
        hasFailed.value = false

        // const _url = new URL(url)
        // _url.search = new URLSearchParams({
        //   [queryParam]: query,
        // }).toString()
        // const response = await fetch(_url)
        // const responseJson = await response.json()

        const resp = suggestModel == 'etiqueta' ? await suggestEtiquetas(tipo, modelo, query.toLowerCase())
          : suggestModel == 'empresa' ? await suggestEmpresas(query.toLowerCase())
          : suggestModel == 'producto' ? await suggestProductos(query.toLowerCase())
          : suggestModel == 'contacto' ? await suggestContactos(query.toLowerCase())
            : await suggestResponsables(tipo, modelo, suggestModel, query.toLowerCase());

        resp.data.map(r => {
          if(r.nombre) r.nombre = removeAccents(r.nombre);
          else r.name = removeAccents(r.name);

          return r;
        });

        console.log(resp.data);

        data.value = transformData(resp.data)
      } catch (error) {
        hasFailed.value = true
      } finally {
        isLoading.value = false
      }
    }



    return {
      isLoading,
      isLoading,
      hasFailed,
      data,
      fetchSuggestions: debounce(fetchSuggestions, debounceDuration),
    }
}
