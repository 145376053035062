import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';
import $store from '@/store'

const URL = apiGlobalConstants.publico;

export const sedesList = () => {
  return httpClient.get(`${URL}/sedes`).then(res => {
    // console.warn('Get sedes')
    $store.commit('addStore', {name: 'sedes',data: [...res.data.data]});

    return res.data;
  });
}

export const cursosList = () => {
  return httpClient.get(`${URL}/cursos`).then(res => {
    // console.warn('Get cursos')
    $store.commit('addStore', {name: 'cursos', data: [...res.data.data]});

    return res.data;
  });
}

export const suggestContactos = (term = "") => {
  let filter = `nombre=${term}`;
  return httpClient.get(`${URL}/contactos?${filter}`).then(res => res.data);
};
