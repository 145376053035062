import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.empresa;

const fetchAllEmpresas = (page = null, filtro = '', perPage = null) => {
  let filter = filtro.length > 0 ? filtro : "";

  if (page != null) {
    return httpClient.get(`${URL}?page=${page}&per_page=${perPage}&${filter}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${URL}?${filter}`).then(res => res.data)
  }
};

const fetchOneEmpresa = (empresaId) => {
  return httpClient.get(`${URL}/${empresaId}`).then(res => res.data);
};

const updateEmpresa = (empresaToEdit) => {
  return httpClient.put(`${URL}/${empresaToEdit.id}`, empresaToEdit).then(res => res.data);
};

const createEmpresa = (data) => {
  return httpClient.post(`${URL}`, data).then(res => res.data);
};

const deleteEmpresa = (empresaId) => {
  return httpClient.delete(`${URL}/${empresaId}`).then(res => res);
};

const asociarResponsable = (administrador_id, empresa_id) => {
  return httpClient.put(`${URL}/asociar-responsable/${empresa_id}`, {administrador_id}).then(res => res);
}

const desasociarResponsable = (administrador_id, empresa_id) => {
  return httpClient.put(`${URL}/desasociar-responsable/${empresa_id}`, {administrador_id}).then(res => res);
}

// api/admin/empresas/suggest
const suggestEmpresas = (term = "") => {
  let filter = term.length > 0 ? `nombre=${term}` : "";
  return httpClient.get(`${URL}/suggest?${filter}`).then(res => res.data);
};

export {
  fetchAllEmpresas,
  fetchOneEmpresa,
  updateEmpresa,
  deleteEmpresa,
  createEmpresa,
  asociarResponsable,
  desasociarResponsable,
  suggestEmpresas
}
