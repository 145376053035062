
import { defineRule, configure, extend } from 'vee-validate'
import { localize } from '@vee-validate/i18n'

import { required, email, confirmed, ext, size, image, min_value, max_value, digits } from '@vee-validate/rules'

// import moment from 'moment';

defineRule('digits', digits)
defineRule('required', required)
defineRule('validEmail', email)
defineRule('confirmed', confirmed)
defineRule('ext', ext);
defineRule('size', size);
defineRule('image', image);
defineRule('minValue', min_value);
defineRule('maxValue', max_value);
defineRule('tieneId', value => {
  return value && value.id
});
defineRule('esPositivo', value => {
  return value >= 0
});


defineRule('esPositivoOrNull', value => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }

  return value >= 0
});

defineRule('requiredId', value => {
  return value && value.id
});

defineRule('maxDate', (value, params) => {
  return Date.parse(value) < Date.parse(params[0])
})

defineRule('minTodayDate', (value) => {
  return Date.parse(value) > Date.parse(Date())
})

defineRule('minDate', (value, params) => {
  return Date.parse(value) > Date.parse(params[0])
})

defineRule('maxDateOrNull', (value, params) => {
  if (!value || !value.length || params[0] === 'null') {
    return true;
  }

  return Date.parse(value + ':00') < Date.parse(params[0] + ':00');

  // TODO: cambiar libreria.
  // return Date.parse(moment(value).format('YYYY-MM-DD HH:mm')) < Date.parse(moment(params[0]).format('YYYY-MM-DD HH:mm'))

})

defineRule('minDateOrNull', (value, params) => {

  if (!value || !value.length || params[0] === 'null') {
    return true;
  }

  return Date.parse(value + ':00') > Date.parse(params[0] + ':00');

  // TODO: cambiar libreria.
  // return Date.parse(moment(value).format('YYYY-MM-DD HH:mm')) < Date.parse(moment(params[0]).format('YYYY-MM-DD HH:mm'))
})

defineRule('validMovil', value => {
  if (!value) return true; // allow empty values to pass
  return /^[67]/.test(value) ? true : false;
});


configure({
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: false,
  validateOnModelUpdate: false,

  generateMessage: localize('es', {
    messages: {
      required: 'El campo {field} no puede estar vacío',
      validEmail: 'Introduce un email válido',
      confirmed: 'Las contraseñas deben coincidir',
      tieneId: 'Debes seleccionar una opción',
      requiredId: 'Debes completar el campo {field} ',
      esPositivo: 'El número debe ser positivo',
      ext: 'El formato no es el requerido',
      size: 'El tamaño del archivo no es válido',
      image: 'El campo debe ser una imagen',
      minDate: 'La fecha debe ser mayor que la fecha de inicio',
      maxDate: 'La fecha debe ser menor que la fecha final',
      minDateOrNull: 'La fecha debe ser mayor que la fecha de inicio',
      maxDateOrNull: 'La fecha debe ser menor que la fecha final',
      minTodayDate: 'La fecha debe ser mayor que la fecha actual',
      digits: 'El campo {field} debe ser numerico y contener exactamente 9 digitos',
      validMovil: 'El campo {field} debe comenzar con un 6 o 7'

    }
  })
})


