<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg"  @close="cerrarModal">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>
          <CIcon name="cil-color-border" />
          <strong class="ms-2">Actualizar archivo <span style="font-size:0.8em">(Máx. 8MB)</span></strong>
          <!-- <CCloseButton @click="$emit('closed',false)" class="text-white"/> -->
        </CModalTitle>
      </CModalHeader>

      <spinner-ttt :show="loading"></spinner-ttt>

      <CModalBody>

        <!-- <Form  as="CForm" v-if="file" ref="fotoForm" @submit.prevent class="mt-3" v-slot="{ errors }"> -->
          <CRow>
            <CCol v-if="file.tipo === 'perfil'" sm="12" class="mb-3">
              <CFormLabel class="required">Fotografía de perfil </CFormLabel>

              <!-- <small class="ml-2 mb-3" style="display:block;">(jpg, png)</small> -->

              <DragDropInput
                accept=".jpg,.png"
                size="8388608"
                :modeloId="modeloId"
                :modeloTipo="modeloTipo"
                :anteriorFichero="file"
                @saveFile="saveFile($event)"
                @erroFile="disabled = true"
              />

              <!-- <Field
                as="input" type="file" name="fotografia"
                class="form-control"
                size="8388608"
                accept="image/*"
                v-model="fotografíaToEdit"
                :rules="{required: true,image: true,size:8388608,ext: ['jpg','png']}"
                v-bind:invalid-feedback="errors.fotografia"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'fotografia')">{{ errors.fotografia }}</small> -->

            </CCol>

            <CCol v-if="file.tipo === 'curriculum'" sm="12" class="mb-3">
              <CFormLabel class="required">Currículum</CFormLabel>

              <!-- <small class="ml-2 mb-3" style="display:block;">(PDF)</small> -->

              <DragDropInput
                accept=".pdf"
                size="8388608"
                :modeloId="modeloId"
                :modeloTipo="modeloTipo"
                :anteriorFichero="file"
                @saveFile="saveFile($event)"
                @erroFile="disabled = true"
              />

              <!-- <Field
                as="input" type="file" name="cv"
                class="form-control"
                size="8388608"
                accept="application/*"
                v-model="curriculumToEdit"
                :rules="{required: true, ext: ['pdf'],size:8388608}"
                v-bind:invalid-feedback="errors.cv"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'cv')">{{ errors.cv }}</small> -->

            </CCol>

            <CCol v-if="file.tipo === 'lesion'" sm="12" class="mb-3">
              <CFormLabel class="required">Fotografía de perfil </CFormLabel>

              <!-- <small class="ml-2 mb-3" style="display:block;">(jpg, png)</small> -->

              <DragDropInput
                accept=".jpg,.png"
                size="8388608"
                multiple
                :modeloId="modeloId"
                :modeloTipo="modeloTipo"
                :anteriorFichero="file"
                @saveFile="saveFile($event)"
                @erroFile="disabled = true"
              />

              <!-- <Field
                as="input" type="file" name="fotografia"
                class="form-control"
                size="8388608"
                accept="image/*"
                v-model="fotografíaToEdit"
                :rules="{required: true,image: true,size:8388608,ext: ['jpg','png']}"
                v-bind:invalid-feedback="errors.fotografia"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'fotografia')">{{ errors.fotografia }}</small> -->

            </CCol>
          </CRow>
        <!-- </Form> -->
       <toast ref="toast"></toast>
      </CModalBody>


      <CModalFooter>
        <CButton @click="cerrarModal" class="text-secondary" color="link">Cancelar</CButton>
        <CButton class="text-light" @click="editOrNewFile" :disabled="disabled" color="primary">Aceptar</CButton>
       </CModalFooter>

    </CModal>
  </div>
</template>

<script>
import { isProxy, toRaw } from 'vue';

import { formError, createBase64 } from '@/app/shared/utils/tools'
import DragDropInput from '@/app/shared/components/DragDropInput';
import { updateFichero, asociarFichero } from '../../global/services/fichero-service';

export default {
  name: 'EditarFicherosPerfil',
  components: { DragDropInput },
  props: {
    show: { type: Boolean, default: false, required: true },
    fileGuid: { type: String, default: ""},
    file: { type: Object, default() { return {}}},
    elemento: { type:String, default: 'foto de perfil'},
    modeloId: { type: Number, required: true },
    modeloTipo: { type: String, required: true },
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
  data(){
    return {
      fotografíaToEdit: null,
      curriculumToEdit: null,
      loading: false,
      disabled: true,
      dataFile: null,
      filesGuid: [],

    }
  },
  emits:['closed', 'editado'],
  methods: {
    formError(errors, value) {
      return formError(errors, value)
    },

    errorFile() {
      this.$refs.toast.showToast('Hay errores en el formulario', null, "error", "5000");
    },

    saveFile(dataFile) {
      console.log(dataFile,'EditarFicherosPerfil');
      this.filesGuid.push(dataFile)
      this.dataFile = {...dataFile};
      this.disabled = false;
    },

    async asociarFichero() {

      this.loading = true;
      /**
        {id: 87, nombre: "Foto perfil", parent_id: 86}, Fotografía de perfil
        {id: 88, nombre: "Curriculum", parent_id: 86},  Currículium
      */
     
     try {
        for (const file of this.filesGuid) { 
          let dataFichero = { ...file, categoria_id: 2, titulo: 'Fotografía de perfil', visible: true};
          
          const resp = await asociarFichero(this.modeloTipo, this.modeloId, dataFichero)
          if (resp.status != 'success') throw resp.message

          this.$refs.toast.showToast(`Fichero asociado`, null, 'success', '2000');
        }

        //DE MOMENTO PONGO ESTO PARA QUE SE CIERRE Y NO DE PROBLEMAS
        this.cerrarModal();
        this.$emit('editado', "perfil");
      }
      catch (err) {
        console.log(err);
        this.$refs.toast.showToast(`Error al asociar el fichero`, null, 'error', '5000');
      }
    },

    editOrNewFile() {
      // check si es nuevo archivo o editar el actual
      if(this.file.nuevo) {
        this.asociarFichero();
      } else {
        this.updateFile();
      }
    },

    async updateFile() {

      try {
        this.loading = true;

        let data = { ...this.file,
        guid: this.dataFile.guid };

        let dataFichero = data;
        if (isProxy(data)) {
          dataFichero = toRaw(data);
        }
        
        const resp = await updateFichero(dataFichero);
        if (resp.status !== 'success') throw resp.message;

        // this.loading = false;
        this.$emit('editado', dataFichero.tipo);

      } catch(err) {
        console.log(err);
        // this.loading = false;
        this.$refs.toast.showToast(`Error al editar ${this.elemento}`, null, 'error', '5000');
      }

    },

    cerrarModal() {
      this.loading = false;
      this.disabled = true;
      this.fotografíaToEdit = null;
      this.curriculumToEdit = null;
      this.$emit('closed', false);
    },

  },
}
</script>
