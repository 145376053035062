import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.producto

export const fetchAllProducts = (page = null, filtro = '') => {
  let filter = filtro.length > 0 ? filtro : ''

  if (page !== null)
    return httpClient.get(`${URL}?page=${page}&${filter}`).then(r => r.data)
  else
    return httpClient.get(`${URL}?${filter}`).then(r => r.data)
}

export const createProduct = (data) => {
  return httpClient.post(`${URL}`, data).then(res => res.data);
}

export const getProduct = (id) => {
  return httpClient.get(`${URL}/${id}`).then(res => res.data);
};

export const updateProduct = (data) => {
  return httpClient.put(`${URL}/${data.id}`, data).then(r => r.data)
}

export const deleteProduct = (id) => {
  return httpClient.delete(`${URL}/${id}`).then(res => res.data);
}

export const suggestProductos = (term = "") => {
  let filter = `nombre=${term}`;
  return httpClient.get(`${URL}/suggest?${filter}`).then(res => res.data);
};

