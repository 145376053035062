import { httpClient, setResponseType, setHeader } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

// URL base, completar con modelos con ficheros disponibles
const URL = apiGlobalConstants.centro;
const URL_FICHEROS = apiGlobalConstants.fichero;

// ASOCIADO  a los modulos con ficheros
// subir-fichero    POST
// asociar-fichero  PUT
// tipos-fichero    GET
// {modelo}/ficheros/{modelo_id}    GET

// ID fichero
//show', 'update', 'destroy


//  i.e   api/admin/empresas/ficheros/{id}
export const fetchAllFicheros = (type, elementID, page = null, perPage = null) => {
  // modelo = 'App\Models\Base\Empresas',
  //`element=${modelo}&element_id=${elementID}`;
  const filter = ''; 

  if (page != null) {
    return httpClient.get(`${URL}/${type}/ficheros/${elementID}?page=${page}&per_page=${perPage}&${filter}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${URL}/${type}/ficheros/${elementID}?${filter}`).then(res => res.data)
  }
};

// Obtener las categorías de los modulos con ficheros.  i.e  api/admin/empresas/tipos-fichero
export const fetchTiposFichero = (type) => {
  return httpClient.get(`${URL}/${type}/tipos-fichero`).then(res => res.data)
}


// POST{{BASE_URL_ADMIN}}/empresas/subir-fichero/200
// BODY nombre, fichero
export const subirFichero = (type, modeloId, fichero, guid = null) => {
  let dataFile = {
    fichero: fichero.result,
    nombre: fichero.name,
    // size: fichero.size, // no
    // guid
  }

  if ( guid ) dataFile['guid'] = guid;

  return httpClient.post(`${URL}/${type}/subir-fichero/${modeloId}`, dataFile).then(res => res.data)
};

// PUT {{BASE_URL_ADMIN}}/empresas/asociar-fichero/200?guid=6638C0F1-EF68-3AD8-17CF-E95782B5BD6B&titulo=Fichero de prueba&bloqueado=1&visible=1&fecha_visualizacion=10-02-2024&categoria_id=87
export const asociarFichero = (type, modeloId, data) => {
  const dataFile = {
    guid: data.guid,
    titulo: data.titulo,
    bloqueado: data.bloqueado ? true : false, 
    visible: data.visible ? true : false,
    // fecha_visualizacion: data.fecha_visualizacion ? moment(data.fecha_visualizacion).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY'),
    categoria_id: data.categoria_id,
    observaciones: data.observaciones
  }

  return httpClient.put(`${URL}/${type}/asociar-fichero/${modeloId}`, dataFile).then(res => res.data)
}

export const fetchOneFichero = (ficheroId) => {
  setHeader({key: "Cache-Control" , value: "no-cache" });
  return httpClient.get(`${URL_FICHEROS}/${ficheroId}`).then(res => res.data);
};

export const updateFichero = (ficheroToEdit) => {

  // 'guid',
  // 'bloqueado',
  // 'visible',
  // 'restriccion_visibilidad',
  // 'categoria_id',
  // 'titulo',
  // 'observaciones'

  setHeader({key: "Cache-Control" , value: "no-cache" });
  return httpClient.put(`${URL_FICHEROS}/${ficheroToEdit.id}`, ficheroToEdit).then(res => res.data);
};

export const deleteFichero = (ficheroId) => {
  return httpClient.delete(`${URL_FICHEROS}/${ficheroId}`).then(res => res.data);
};

// ficheros/download/{id}
export const downloadFichero = (guid)  => {
  setResponseType('blob');
  return httpClient.get(`${URL_FICHEROS}/download/${guid}`).then(res => {
    setResponseType('json');
    return res
  });
}



