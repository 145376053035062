<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler> 

      <!-- <CNavLink class="clickeable" href="/#/aula-virtual/asignaturas/2?master_id=27">
        <CIcon size="lg" name="cil-check-circle" class="me-2" /> Asignatura
      </CNavLink>

      <CNavLink class="clickeable" href="/#/aula-virtual/asignaturas/2/tema/2?master_id=27&asignatura_id=2">
        <CIcon size="lg" name="cil-x-circle" class="me-2" /> Tema
      </CNavLink> -->


      <CHeaderNav>
      <AppHeaderAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppHeaderAccnt from './AppHeaderAccnt'
import AppHeaderDropdownMssgs from './AppHeaderDropdownMssgs'
import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'
import { logo } from '@/assets/brand/logo'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
    AppHeaderDropdownMssgs,
    AppHeaderDropdownNotif,
    AppHeaderDropdownTasks,
    AppHeaderAccnt
  },
  setup() {
    return {
      logo,
    }
  },
}
</script>
