import { ADMIN_API, CENTROS_API, BASE_URL } from "@/config";

export const apiGlobalConstants = {
  admin: ADMIN_API,
  centro: CENTROS_API,
  administrador: `${CENTROS_API}/administradores`,
  dashboard: `${BASE_URL}/centros`,
  contacto: `${ADMIN_API}/contactos`,
  empresa: `${ADMIN_API}/empresas`,
  persona: `${ADMIN_API}/personas`,
  tipologia: `${BASE_URL}/publico/tipologias`,
  sede: `${ADMIN_API}/sedes`,
  comentario: `${ADMIN_API}/comentarios`,
  producto: `${ADMIN_API}/productos`,
  curso: `${ADMIN_API}/cursos`,
  fichero: `${CENTROS_API}/ficheros`,
  master: `${ADMIN_API}/masters`,
  profesor: `${ADMIN_API}/profesores`,
  direccion: `${CENTROS_API}/direcciones`,
  publico : `${ADMIN_API}/publico`,
  cuota: `${ADMIN_API}/cuotas`,
  alumno: `${ADMIN_API}/alumnos`,
  matricula: `${ADMIN_API}/matriculas`,
  beca: `${ADMIN_API}/becas`,
  convocatoria: `${ADMIN_API}/convocatorias`,
  log: `${CENTROS_API}/logs`,
  grupo: `${ADMIN_API}/grupos`,
  gasto: `${ADMIN_API}/gastos`,
  venta: `${ADMIN_API}/ventas`,
  informes: `${CENTROS_API}/informes`,
  movimiento: `${ADMIN_API}/movimientos`,
  lista: `${ADMIN_API}/listas`,
  factura: `${ADMIN_API}/facturas`,
  expedientes: `${CENTROS_API}/expedientes`,
  pacientes: `${CENTROS_API}/pacientes`,
  medicos: `${CENTROS_API}/medicos`,
  centros: `${CENTROS_API}/centro`,
  saldos: `${CENTROS_API}/movimientos`,
  lesiones: `${CENTROS_API}/lesiones`,
  manual: `${CENTROS_API}/manual`,
  publica: `${BASE_URL}/expedientes`,
}
