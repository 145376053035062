
import { httpClient, setResponseType } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

// domain/api/admin
const BASE_API = apiGlobalConstants.admin;

const fetchAllData = (page = null, filtro = '') => {
  let filter = filtro.length > 0 ? filtro : "";

  if (page != null) {
    return httpClient.get(`${BASE_API}?page=${page}${filter}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${BASE_API}?${filter}`).then(res => res.data)
  }
};

export const downloadExcel = (filename, model = null ) => {
  setResponseType('blob');
  return httpClient.post(`${model}/to-excel`, {name: filename}).then(res => {
    setResponseType('json');
    return res
  });
};

export const resetResponseType = () => {
  setResponseType('json');
};


export const crearListaMkt = (entidad, nombre_lista, filtro = '') => {
  let filter = filtro.length > 0 ? filtro : "";
  return httpClient.post(`${BASE_API}?${filter}`, { entidad, nombre_lista }).then(res => res.data)
}



