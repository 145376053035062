<template>
    <div class="animated fadeIn">
      <CModal v-model:visible="showModal" size="lg"  @close="cerrarModal">
  
        <CModalHeader class="bg-primary text-light">
          <CModalTitle>
            <CIcon name="cil-color-border" />
            <strong class="ms-2">Añadir archivos a la lesión <span style="font-size:0.8em">(Máx. 8MB) (Máx. 4 Imágenes)</span></strong>
            <!-- <CCloseButton @click="$emit('closed',false)" class="text-white"/> -->
          </CModalTitle>
        </CModalHeader>
  
        <spinner-ttt :show="loading"></spinner-ttt>
  
        <CModalBody>
  
          <!-- <Form  as="CForm" v-if="file" ref="fotoForm" @submit.prevent class="mt-3" v-slot="{ errors }"> -->
            <CRow>
              <CCol sm="12" class="mb-3">
                <CFormLabel class="required">Imágenes </CFormLabel>
  
                <!-- <small class="ml-2 mb-3" style="display:block;">(jpg, png)</small> -->
  
                <DragDropInput
                  size="8388608"
                  multiple
                  :maxFiles="4"
                  :modeloId="modeloId"
                  :modeloTipo="modeloTipo"
                  :anteriorFichero="file"
                  @cleanFiles="cleanFiles($event)"
                  @saveFile="saveFile($event)"
                  @erroFile="disabled = true"
                />
  
                <!-- <Field
                  as="input" type="file" name="fotografia"
                  class="form-control"
                  size="8388608"
                  accept="image/*"
                  v-model="fotografíaToEdit"
                  :rules="{required: true,image: true,size:8388608,ext: ['jpg','png']}"
                  v-bind:invalid-feedback="errors.fotografia"
                />
                <small class="text-danger ml-2" v-if="formError(errors, 'fotografia')">{{ errors.fotografia }}</small> -->
  
              </CCol>
            </CRow>
          <!-- </Form> -->
         <toast ref="toast"></toast>
        </CModalBody>
  
  
        <CModalFooter>
          <CButton @click="cerrarModal" class="text-secondary" color="link">Cancelar</CButton>
          <CButton class="text-light" @click="editOrNewFile" :disabled="disabled" color="primary">Aceptar</CButton>
         </CModalFooter>
  
      </CModal>
    </div>
  </template>
  
  <script>
  import { isProxy, toRaw } from 'vue';
  
  import { formError, createBase64 } from '@/app/shared/utils/tools'
  import DragDropInput from '@/app/shared/components/DragDropInput';
  import { updateFichero, asociarFichero } from '../../global/services/fichero-service';
import { resetResponseType } from '@/app/shared/global/services/global-service';
  
  export default {
    name: 'EditarFicherosPerfil',
    components: { DragDropInput },
    props: {
      show: { type: Boolean, default: false, required: true },
      fileGuid: { type: String, default: ""},
      file: { type: Object, default() { return {}}},
      elemento: { type:String, default: 'foto de perfil'},
      modeloId: { type: Number, required: true },
      modeloTipo: { type: String, required: true },
    },
    computed: {
      showModal() {
        return this.show;
      },
    },
    data(){
      return {
        fotografíaToEdit: null,
        curriculumToEdit: null,
        loading: false,
        disabled: true,
        dataFile: null,
        filesGuid: [],
      }
    },
    emits:['closed', 'editado'],
    methods: {
      formError(errors, value) {
        return formError(errors, value)
      },
      cleanFiles(dataFile) { 
        this.filesGuid = [] 
      },
      errorFile() {
        this.$refs.toast.showToast('Hay errores en el formulario', null, "error", "5000");
      },
  
      saveFile(dataFile) {
        console.log(dataFile,'EditarFicherosPerfil');
        this.filesGuid.push(dataFile)
        this.dataFile = {...dataFile};
        this.disabled = false;
      },
      editOrNewFile() {
        // check si es nuevo archivo o editar el actual
        if(this.file.nuevo) {
          this.asociarFichero();
        } else {
          this.updateFile();
        }
      },
      async asociarFichero() {
  
        this.loading = true;
        /**
          {id: 87, nombre: "Foto perfil", parent_id: 86}, Fotografía de perfil
          {id: 88, nombre: "Curriculum", parent_id: 86},  Currículium
        */
      
        try {
          for (const file of this.filesGuid) {
            let dataFichero = { ...file, categoria_id: 1, titulo: 'Fotografía de perfil', visible: true};
      
          
              const resp = await asociarFichero(this.modeloTipo, this.modeloId, dataFichero)
              if (resp.status != 'success') throw resp.message
              resetResponseType();
              this.$refs.toast.showToast(`Fichero asociado`, null, 'success', '2000');
      
              //DE MOMENTO PONGO ESTO PARA QUE SE CIERRE Y NO DE PROBLEMAS
            } 
            this.cerrarModal();
            this.$emit('editado', "perfil");
        }catch (err) {
            console.log(err);
            this.$refs.toast.showToast(`Error al asociar el fichero `, null, 'error', '5000');
          }
      },
  
      cerrarModal() {
        this.loading = false;
        this.disabled = true;
        this.fotografíaToEdit = null;
        this.curriculumToEdit = null;
        this.$emit('closed', false);
      },
  
    },
  }
  </script>
  